import { Provider } from "react-redux"
import store from "./src/redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"

const React = require("react")
export const wrapPageElement = ({ element, props }) => {
  let persistor = persistStore(store)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}

// const appendThirdPartyScripts = () => {
//   appendScript(
//     `
// console.log('hello)`,
//     "src"
//   )

//   sessionStorage.setItem("loadedScripts", true)
// }
// const appendScript = (scriptInnerHTML, type, load) => {}
// const loadedScripts = sessionStorage.getItem("loadedScripts")
// const appendScriptsOnScrollOptions = { once: true }

// export const onInitialClientRender = props => {
//   if (true) {
//     window.addEventListener(
//       "load",
//       appendThirdPartyScripts,
//       appendScriptsOnScrollOptions
//     )
//   } else {
//     appendThirdPartyScripts()
//   }
// }

// export const onRouteUpdate = ({ prevLocation }) => {
//   if (prevLocation) {
//     window.removeEventListener(
//       "load",
//       appendThirdPartyScripts,
//       appendScriptsOnScrollOptions
//     )
//     // appendThirdPartyScripts()
//   }
// }
